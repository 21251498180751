// 區域設定 (news-item) 資料格式
const RegionSettingSchema = {
  id: "",
  useLink: false,
  newsId: "",
  typeId: "",
  typeInfo: null,
  useAd: false,
  adCode: "",
  link: "",
  title: "",
  image: "",
  news: null
}

// 若開啟廣告，送出前清除其他資料
function cleanUpData (_list) {
  return _list.map(_item => _item.useAd
    ? ({
      ...RegionSettingSchema,
      useAd: true,
      adCode: _item.adCode
    })
    : _item
  )
}

// 檢查目前填寫的內容
function checkFromData (_vm, _list, _regionType = null) {
  let searchError = false
  _list.forEach(function (_item) {
    if (_item.useAd) {
      if (!_item.adCode) {
        console.log('=> 廣告碼沒有填入')
        searchError = true
      }
    } else {
      if (!_item.title) {
        console.log('=> 項目標題沒有設定');
        searchError = true
      } else if ((_regionType !== 14 && !_item.image)) {
        console.log('=> 版面不符合或圖片沒有設定')
        searchError = true
      } else if (_item.useLink && !_item.link) {
        console.log('=> 使用連結但沒有設定連結')
        searchError = true
      } else if (!_item.newsId && !_item.useLink) {
        console.log('=> 非新聞項目也非使用連結:', _item)
        searchError = true
      }
    }
  })

  if (searchError) {
    console.log('=> [' + _regionType + ']版位設定有問題....')
    _vm.$root.common.showErrorDialog("請確定欄位都有填寫完成哦！")
    return false
  }

  return true
}

// 處理區域設定資料格式
function formatRegionNewsList (_list) {
  if (_list.length === 0) return []
  let items = []
  _list.forEach(function (_row, _index) {

    if (_row.news_id) {
      const newsOption = {
        id: _row.news_id,
        code: _row.news_code,
        name: '(' + _row.news_code + ')' + _row.title
      }

      items.push({
        ...RegionSettingSchema,
        newsId: _row.news_id,
        typeId: _row.type_info.id,
        typeInfo: _row.type_info,
        title: _row.title,
        image: _row.image,
        news: newsOption,
        webTheme: (_row.web_theme_type == 2) ? true : false,
        appTheme: (_row.app_theme_type == 2) ? true : false,
      })
    } else if (_row.use_link) {
      items.push({
        ...RegionSettingSchema,
        useLink: _row.use_link,
        link: _row.link,
        title: _row.title,
        image: _row.image,
      })
    } else {
      items.push({
        ...RegionSettingSchema,
        useAd: _row.use_ad,
        adCode: _row.ad_code
      })
    }
  })
  return items
}

// 處理匯入新聞資料格式
function formatImportNews (_news) {

  let newsId = (_news.news) ? _news.news.id : _news.id
  let newsCode = (_news.news) ? _news.news.code : _news.code
  let newsName = (_news.news) ? _news.news.name : _news.title

  let newsOption = {
    id: newsId,
    code: newsCode,
    name: '(' + newsCode + ')' + newsName
  }

  let mainType = {}
  if (_news.main_type) {
    mainType = _news.main_type
  }

  else {
    mainType = _news.typeInfo
  }

  return {
    id: "",
    useLink: false,
    newsId: newsId,
    typeId: mainType.id,
    typeInfo: mainType,
    useAd: false,
    adCode: "",
    link: "",
    title: _news.title,
    image: _news.image,
    news: newsOption
  }
}

// 處理匯入連結資料格式
function formatImportLinks (_items) {
  return {
    id: "",
    useLink: true,
    newsId: "",
    typeId: "",
    typeInfo: {},
    useAd: false,
    adCode: "",
    link: _items.link,
    title: _items.title,
    image: _items.image,
    news: {}
  }
}

export {
  RegionSettingSchema,
  checkFromData,
  cleanUpData,
  formatRegionNewsList,
  formatImportNews,
  formatImportLinks
}