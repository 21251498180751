<template lang="pug">
b-card-body(v-if="itemData")
  ChoiceNews(:key="'ChoiceNews' + index" :show_status="showStatus" @returnNews="onNewsUpdate")
  form
    .row.col-12(v-if="canUseAd")
      .col-auto.pr-1.pt-1.pl-0
        switches.mb-0(
          :value="itemData.useAd", 
          @input="onInputChange($event, 'useAd')" 
          type-bold="false", 
          color="primary"
          :disabled="!allowEdit"
        )
      .col-6.col-lg-10.pl-0
        label(v-if="!itemData.useAd") 廣告未開啟
        label(v-if="itemData.useAd") 廣告已開啟

    .row.form-group(v-if="!itemData.useAd")
      .col-lg-12
        b-form-checkbox(
          :checked="itemData.useLink", 
          @change="onUseLinkChange", 
          name="checkbox"
          :disabled="!allowEdit"
        ) 使用連結

      .col-lg-8.search-box(v-if="!itemData.useLink")
        multiselect(
          :key="'setting-region-item_data-'+ index"
          :value="itemData.news"
          @input="onNewsSelected"
          :options="NewsOptions",
          placeholder="請輸入稿件編號",
          label="name",
          track-by="code",
          :multiple="false"
          @search-change="searchNewsList"
          :disabled="!allowEdit"
          select-label=""
        )

      .col-lg-3.px-2.p-lg-0(v-if="!itemData.useLink")
        b-button.action-btn(:disabled="!allowEdit" @click="openChoice()" variant="primary")  
          span(v-if="itemData.typeInfo") {{ itemData.typeInfo.father?.name }} / {{ itemData.typeInfo?.name }}
          span(v-else) 請選擇分類

      .col-lg-12.search-box(v-if="itemData.useLink")
        b-form-input.form-control(type="text", :value="itemData.link", @input="onInputChange($event, 'link')", placeholder="請輸入連結" :disabled="!allowEdit")
      .col-lg-12
        b-form-input.form-control(type="text", :value="itemData.title", @input="onInputChange($event, 'title')" placeholder="請輸入標題" :disabled="!allowEdit")

      .row.col-12.mt-3(v-if="regionId == 1")
        .pl-2
        .col-auto.pr-1.pt-1.pl-0
          switches.mb-0(
            :key="'theme-switchs-' + index"
            :ref="'theme-switchs-' + index"
            :value="itemData.webTheme", 
            @input="onInputChange($event, 'webTheme')" 
            type-bold="false", 
            color="primary"
            :disabled="!allowEdit"
          )
        .col-auto.pl-0
          label(v-if="!itemData.webTheme") Web版面置中
          label(v-if="itemData.webTheme") Web版面置中
        
        .col-auto.pr-1.pt-1.pl-0
          switches.mb-0(
            :key="'theme-switchs-' + index"
            :ref="'theme-switchs-' + index"
            :value="itemData.appTheme", 
            @input="onInputChange($event, 'appTheme')" 
            type-bold="false", 
            color="primary"
            :disabled="!allowEdit"
          )
        .col-auto.pl-0
          label(v-if="!itemData.appTheme") App版面置中
          label(v-if="itemData.appTheme") App版面置中

      .col-lg-12.row(v-if="showImage")
        .col(v-if="!itemData.image")
          .home-big-picture(v-if="regionId == 1")
            vue-dropzone(
              :ref="'dropzone-0' + index + '-img'"
              :id="'dropzone-0' + index + '-img'", 
              :key="'dropzone-0' + index + '-img'", 
              ref="myVueDropzone", 
              :use-custom-slot="true", 
              :options="dropzoneOptions",
              @vdropzone-success-multiple="uploadImageSuccess"
            )
              .dz-message.needsclick
                i.h1.text-muted.ri-upload-cloud-2-line
                h4 上傳圖片

          .other-picture(v-else)
            vue-dropzone(
              :ref="'dropzone-0' + index + '-img'"
              :id="'dropzone-0' + index + '-img'", 
              :key="'dropzone-0' + index + '-img'", 
              ref="myVueDropzone", 
              :use-custom-slot="true", 
              :options="dropzoneOptions",
              @vdropzone-success-multiple="uploadImageSuccess"
            )
              .dz-message.needsclick
                i.h1.text-muted.ri-upload-cloud-2-line
                h4 上傳圖片

        .col.pt-2(v-if="itemData.image")
          .row(v-if="regionId == 1")
            .col-auto
              .d-flex.align-items-end
                .card.mb-0.shadow-none
                  .dropzone-previews
                    .big-pc-view
                      img.region-1.preview-img.avatar-sm.rounded.bg-light(:src='itemData.image' alt='')
                      a.img-del-btn.btn.btn-link.btn-lg.text-muted(v-if="allowEdit" @click="deleteImg('big-pc')")
                        i.fe-x
              p.mb-0.ml-2 (大尺吋：1920 x 870) 
            .col-auto
              .d-flex.align-items-end
                .card.mb-0.shadow-none
                  .dropzone-previews
                    .pc-view
                      img.region-1.preview-img.avatar-sm.rounded.bg-light(:src='itemData.image' alt='')
                      //- a.img-del-btn.btn.btn-link.btn-lg.text-muted(v-if="allowEdit" @click="deleteImg('pc')")
                        i.fe-x
              p.mb-0.ml-2 (中尺吋：1140 x 870) 
            .col-auto
              .d-flex.align-items-end
                .card.mb-0.shadow-none
                  .dropzone-previews
                    .phone-view
                      img.region-1.preview-img.avatar-sm.rounded.bg-light(:src='itemData.image' alt='')
                      //- a.img-del-btn.btn.btn-link.btn-lg.text-muted(v-if="allowEdit" @click="deleteImg('mobile')")
                        i.fe-x
              p.mb-0.ml-2 (APP尺吋：390 x 632) 

          .row(v-else-if="regionId === 46")
            .col
              .d-flex.align-items-end
                .card.mb-0.shadow-none
                  .dropzone-previews
                    .base-view
                      img.preview-img.avatar-sm.rounded.bg-light(:src='itemData.image' alt='')
                      a.img-del-btn.btn.btn-link.btn-lg.text-muted(v-if="allowEdit" @click="deleteImg('base')")
                        i.fe-x
              p.mb-0.ml-2 (尺吋： 400 x 300) 

          .row(v-else)
            .col
              .d-flex.align-items-end
                .card.mb-0.shadow-none
                  .dropzone-previews
                    .base-view
                      img.preview-img.avatar-sm.rounded.bg-light(:src='itemData.image' alt='')
                      a.img-del-btn.btn.btn-link.btn-lg.text-muted(v-if="allowEdit" @click="deleteImg('base')")
                        i.fe-x
              p.mb-0.ml-2 (尺吋： 523 x 357) 

    .row.form-group(v-if="itemData.useAd")
      .col-lg-10
        textarea.form-control(
          :value="itemData.adCode",
          @input="onInputChange($event.target.value, 'adCode')"
          rows="3",
          type="text",
          placeholder="請貼上 Google 廣告碼"
          :disabled="!allowEdit"
        )

    .col-12(v-if="allowEdit")
      .del-btn.float-right.width-auto(@click="removeItem(index)")
        b-button.btn(href="javascript:void(0);" size="lg", variant="primary")
          i.mdi.mdi-close 
        span.ml-1.mr-0 刪除
</template>

<script>
import vue2Dropzone from "vue2-dropzone";
import Switches from "vue-switches";
import ChoiceNews from "@/components/ChoiceNews"
import Multiselect from "vue-multiselect";
import { formatImportNews, RegionSettingSchema } from '@/views/pages/marketing-slot/home-index/common.js'
/**
 * Starter component
 */
export default {
  props: {
    item: {
      type: Object,
      default: () => { }
    },
    regionId: {
      type: [Number, String],
      default: ''
    },
    index: {
      type: Number,
      required: true
    },
    canUseAd: {
      type: Boolean,
      default: false
    },
    allowEdit: {
      type: Boolean,
      default: true
    },
    showImage: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      dropzoneOptions: {
        url: process.env.VUE_APP_UPLOAD_SERVER_URI + "/media/create/news/default",
        method: "post",
        uploadMultiple: true,
        paramName: "files",
        headers: {
          "Authorization": 'Bearer ' + this.$root.common.getCookie('api-token'),
        },
        previewTemplate: this.template(),
      },
      NewsOptions: [],
      showStatus: false,
      // 元件內顯示用的新聞項目
      itemData: this.item
    };
  },
  watch: {
    'item' (_val) {
      this.itemData = _val
    },
  },
  mounted () {
    this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
      this.showStatus = false
    })
  },
  methods: {
    // 處理圖片下載模組
    template () {
      return ` <div class="dropzone-previews">
            <div class="card mb-0 shadow-none">
              <img data-dz-thumbnail src="#" class="preview-img avatar-sm rounded bg-light" alt="">
              <!-- Button -->
              <a href="" class="img-del-btn btn btn-link btn-lg text-muted" data-dz-remove>
                  <i class="fe-x"></i>
              </a>
            </div>
        </div>`;
    },
    // 圖片上傳成功時
    uploadImageSuccess (file, response) {
      console.log("Upload Response:", response)
      this.itemData.image = response.data[0].url
      this.$emit('setItemData', this.itemData, this.index)
    },
    // 移除此新聞項目
    removeItem (_index) {
      this.$emit('removeItem', _index, this.regionId)
    },
    // 移除圖片項目
    deleteImg () {
      this.itemData.image = ''
      this.$emit('setItemData', this.itemData, this.index)
    },
    // 開啟新聞選擇器
    openChoice () {
      this.showStatus = true
    },
    // 從新聞選擇器選定新聞項目
    onNewsUpdate (_news) {
      const setItem = formatImportNews(_news)
      this.$emit('setItemData', setItem, this.index)
    },
    // 搜尋延伸新聞的新聞列表
    searchNewsList (_keyword) {
      let vm = this

      this.$root.apis.getFastNewsList({
        name: _keyword
      }, function (_response) {
        let items = _response.body.data.items
        items.forEach(function (_item) {
          vm.NewsOptions.push(_item)
        })
      })
    },
    // 當選擇一篇新聞時
    onNewsSelected (_val) {
      const oldId = this.itemData.news?.id || null
      if (!oldId || (_val && _val.id !== oldId)) {
        this.getNewsDetail(_val.id)
      }
    },
    // 獲得單一新聞文章
    getNewsDetail (_id) {
      this.$root.apis.getNewsDetail(_id, this.getNewsDetailSuccess)
    },
    // 當成功獲得新聞資料時
    getNewsDetailSuccess (_res) {
      console.log("NewsDetail:", _res);
      this.onNewsUpdate(_res.body.data)
    },
    // 當切換連結時，一律清空
    onUseLinkChange (_val) {
      const setItem = {
        ...RegionSettingSchema,
        useLink: _val
      }
      this.$emit('setItemData', setItem, this.index)
    },
    // 其他內容編輯時
    onInputChange (_val, _key) {
      const setItem = {
        ...this.itemData,
        [_key]: _val
      }
      this.$emit('setItemData', setItem, this.index)
    }
  },
  components: {
    vueDropzone: vue2Dropzone,
    Switches,
    ChoiceNews,
    Multiselect
  },
};
</script>

<style lang="sass" scoped>
::v-deep.card-body
  overflow: scroll

::v-deep.vue-dropzone
  padding: 0
  width: 100%
  height: 100%
  max-height: 170px
  max-width: 282px

::v-deep .dropzone-previews
  width: 100%
  height: 100%
  .img-del-btn
    position: absolute
    right: -15px
    top: -10px
    background: #1e40b0
    border-radius: 50%
    padding: 0
    height: 30px
    width: 30px
    line-height: 30px
    cursor: pointer
    i
      color: #fff
      cursor: pointer

  img
    width: 100%
    height: 100%
    object-fit: cover

  .big-pc-view
    max-width: 338px
    max-height: 153px
    width: 338px
    height: 153px
    position: relative
    img
      max-width: 338px
      max-height: 153px
      width: 338px
      height: 153px

  .pc-view
    max-width: 200px
    max-height: 153px
    width: 200px
    height: 153px
    img
      max-width: 200px
      max-height: 153px
      width: 200px
      height: 153px

  .phone-view
    max-width: 94px
    max-height: 153px
    width: 94px
    height: 153px
    img
      max-width: 94px
      max-height: 153px
      width: 94px
      height: 153px

  .base-view
    max-width: 224px
    max-height: 153px
    width: 224px
    height: 153px
    position: relative
    img
      max-width: 224px
      max-height: 153px
      width: 224px
      height: 153px
    
.card
  margin-bottom: 2rem
  .form-group
    display: flex
    justify-content: space-start
    align-items: center
    flex-wrap: wrap
    gap: 14px
    .search-box
      position: relative
      #tip
        padding: 0 10px 0 35px
        background-repeat: no-repeat
        background-position: 10px 7px
      .fa-search
        position: absolute
        top: 30%
        margin: auto
  .del-btn
    flex: 1
    display: flex
    justify-content: flex-end
    align-items: center
    cursor: pointer
    .btn
      padding: 0rem .3rem
      border-radius: 50px

.action-btn
  width: 100%

@media (max-width: 992px)
  .action-btn
    display: block
    width: 100%
</style>