<template lang="pug">
.px-0.mb-3
  .row
    .col-lg-12
      .form-group.mb-3
        label(v-if="showTitle")  內容快速配置：
          select(v-model="autoImportNews")
            option(value="0") 手動更新
            option(value="1") 自動更新
          select(v-if="autoImportNews === '1'" v-model="autoImportTimes")
            option(v-for="time in [1,3,5,10,15,30]" :value="time") 每{{ time }}分鐘
          span
            i.mt-1.ml-1.fa.fa-info-circle.text-muted.float-right.info-btn(
              v-b-tooltip.hover
              :title="'透過設定新聞挑選方式，可快速將對應分類的新聞帶入或自動刷新'"
            )
        .row(v-if="[8,19,33,32,34].indexOf(regionType) > -1 || autoImportNews === '0'")
          .col-md-3
            .form-group.mb-2
              input.form-control(
                v-model="importCount", 
                type="text", 
                :placeholder="'請輸入新聞則數' + (( showMaxCount ) ? '(最多'+ maxCount +'則)' : '')"
                :disabled="isLock"
              )
        .div.d-flex
          b-form-radio(v-model='selected' name='import-method' value='news')
          .row.min-w960
            .col-12.col-lg-2
              .form-group.mb-2
                multiselect(
                  key="import-news-main-type"
                  v-model="importMainNewsType",
                  :options="$root.newsTypeList",
                  placeholder="請選擇主分類",
                  track-by="id",
                  label="name",
                  select-label=""
                  :disabled="( selected !== 'news' )"
                )
            .col-12.col-lg-2(v-if="showSubType")
              .form-group.mb-2
                multiselect(
                  :disabled="!importMainNewsType || ( selected !== 'news' )"
                  key="import-news-sub-type"
                  v-model="importSubNewsType",
                  :options="(importMainNewsType && importMainNewsType.sub_types) || []",
                  placeholder="請選擇次分類",
                  track-by="id",
                  label="name",
                  select-label=""
                )
            .col-12.col-lg-2
              .form-group.mb-2
                multiselect(
                  key="import-news-methods"
                  v-model="NewsImportMethods",
                  :options="methodsOptions",
                  placeholder="請選擇挑選方式",
                  track-by="id",
                  label="name",
                  select-label=""
                  :disabled="( selected !== 'news' )"
                )
        .div.d-flex
          b-form-radio(v-model='selected' name='import-method' value='tags')
          .row.min-w510
            .col-12.col-lg-5
              .form-group.mb-2
                multiselect(
                  v-model="currentTags",
                  :options="tags",
                  placeholder="請選擇TAG",
                  label="name",
                  track-by="id",
                  select-label=""
                  :disabled="( selected !== 'tags' )"
                  :multiple="true",
                  :taggable="true",
                  @tag="addTag"
                  @search-change="searchTagList"
                )
            .col-12.col-lg-5
              .form-group.mb-2
                multiselect(
                  key="import-news-methods"
                  v-model="TagImportMethods",
                  :options="methodsOptions",
                  placeholder="請選擇挑選方式",
                  track-by="id",
                  label="name",
                  select-label=""
                  :disabled="( selected !== 'tags' )"
                )
        .div.d-flex
          b-form-radio(v-model='selected' name='import-method' value='videos')
          .row.min-w510
            .col-12.col-lg-5
              .form-group.mb-2
                multiselect(
                  v-model="currentVideos",
                  :options="videos",
                  placeholder="請選擇影音播放清單",
                  label="name",
                  track-by="id",
                  select-label=""
                  :disabled="( selected !== 'videos' )"
                  @search-change="searchVideoList"
                )
            .col-12.col-lg-5
              .form-group.mb-2
                multiselect(
                  key="import-news-methods"
                  v-model="VideoImportMethods",
                  :options="methodsOptions",
                  placeholder="請選擇挑選方式",
                  track-by="id",
                  label="name",
                  select-label=""
                  :disabled="( selected !== 'videos' )"
                )
        b-button.action-btn.width-lg(
          variant="secondary"
          @click="onImport"
          :disabled="( !selected )"
        ) 
          span(v-if="autoImportNews === '1'") 更新設定
          span(v-else) 快速帶入
  hr
</template>

<script>
import Multiselect from "vue-multiselect";
import FastImport from "@/components/FastImport"
import {
  checkFromData,
  formatRegionNewsList,
  formatImportNews,
  RegionSettingSchema
} from '@/views/pages/marketing-slot/home-index/common.js'
export default {
  props: {
    regionType: {
      type: Number,
      default: 0
    },
    regionId: {
      type: Number,
      default: 0
    },
    targetId: {
      type: String,
      default: ""
    },
    maxCount: {
      type: Number,
      default: 10
    },
    showSubType: {
      type: Boolean,
      default: true
    },
    importCountRule: {
      type: Function,
      required: false
    },
    showTitle: {
      type: Boolean,
      default: true
    },
    showMaxCount: {
      type: Boolean,
      default: true
    },
    isLock: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      autoImportTimes: "1",
      autoImportNews: "0",
      selected: 'news',
      // 主要類別
      currentTags: null,
      importMainNewsType: null,
      importSubNewsType: null,
      importMethods: null,
      NewsImportMethods: null,
      TagImportMethods: null,
      importCount: null,
      methodsOptions: [
        {
          id: 1,
          name: '新到舊'
        },
        {
          id: 2,
          name: '舊到新'
        },
        // {
        //   id: 3,
        //   name: '熱門'
        // }
      ],
      tags: [],
      videos: [],
      currentVideos: null,
      VideoImportMethods: null,
      allowedImport: false
    }
  },
  watch: {
    importMainNewsType (_val, _old) {
      if (_val !== _old) {
        this.importSubNewsType = null
      }
    },
    isLock (_val) {
      if (_val) {
        this.reset()
      }
    },
    selected (_val) {
      if (_val === 'news') {
        this.importMethods = this.NewsImportMethods
      }
      if (_val === 'tags') {
        this.importMethods = this.TagImportMethods
      }
    },
    regionType () {

    },
    targetId () {
      // 監測到目標對象改變
      this.reloadData()
    },
  },
  mounted () {
    this.searchTagList()
    this.reloadData()
  },
  computed: {
    disabledImport () {
      return !this.importMethods || (this.showMaxCount && !this.importCount)
    }
  },
  methods: {
    reloadData () {
      this.getMarketAutoSetting()
    },
    // 搜尋符合的標籤
    searchTagList (_text) {
      let vm = this
      console.log("=> 標籤搜尋:", _text)
      this.$root.apis.getFastTagList({
        name: _text,
        per_page: 100
      }, function (_response) {
        vm.tags = _response.body.data.items
      })
    },
    // 搜尋符合的影音播放清單
    searchVideoList (_text) {
      let vm = this
      console.log("=> 播放清單搜尋:", _text)
      this.$root.apis.getFastVideoList({
        name: _text,
        per_page: 100
      }, function (_response) {
        vm.videos = _response.body.data.items
      })
    },
    onImport () {
      let vm = this

      // 針對三宮格，僅限輸入 3 的倍數
      if (this.regionType === 8) {
        if (this.importCount % 3 !== 0) {
          return this.$root.common.showErrorNotify("三宮格只能輸入 3 的倍數哦！")
        }
      }
      // 影音新聞列表，僅限輸入 3 的倍數
      if (this.regionType === 33) {
        if (this.importCount % 3 !== 0) {
          return this.$root.common.showErrorNotify("影音新聞列表只能輸入 3 的倍數哦！")
        }
      }

      if (this.importCountRule) {
        const passRule = this.importCountRule(this.importCount)
        if (!passRule) return
      }
      if (this.maxCount && this.importCount > this.maxCount) {
        return this.$root.common.showErrorNotify("超過新聞則數限制！")
      }

      if (this.selected === 'news') {
        // 處理必填項目
        if (this.importMainNewsType === null) {
          return this.$root.common.showErrorNotify("新聞主要分類為必填項目")
        }
        this.importMethods = this.NewsImportMethods
      }
      if (this.selected === 'tags') {
        if (this.currentTags === null) {
          return this.$root.common.showErrorNotify("新聞標籤為必填項目")
        }
        if (this.currentTags.length === 0) {
          return this.$root.common.showErrorNotify("新聞標籤為必填項目")
        }
        if (this.currentTags.length > 3) {
          return this.$root.common.showErrorNotify("新聞標籤最多只能選擇 3 個哦！")
        }
        this.importMethods = this.TagImportMethods
      }
      if (this.selected === 'videos') {
        if (this.currentVideos === null) {
          return this.$root.common.showErrorNotify("影音清單為必填項目")
        }
        this.importMethods = this.VideoImportMethods
      }

      let importParam = {
        search_source: 2,
        main_type: (this.importMainNewsType !== null) ? this.importMainNewsType.id : "",
        tag: (this.currentTags !== null) ? this.currentTags : [],
        live: (this.currentVideos !== null) ? this.currentVideos.id : "",
        sort: (this.importMethods !== null) ? this.importMethods.id : "",
        per_page: (this.importCount) ? this.importCount : 0
      }

      if (this.showSubType) {
        importParam.sub_type = (this.importSubNewsType !== null) ? this.importSubNewsType.id : ""
      }
      console.log('=> 處理篩選條件:', importParam)

      if (this.autoImportNews === "1") {
        this.$root.common.confirmAction(
          "版面自動設置",
          '確認設置為自動載入模式嗎，此操作將即時生效！',
          "確定", "不要",
          function () {
            vm.setRegionAutoSetting(importParam)
          })
        return
      }

      if (this.selected === 'news' || this.selected === 'tags') {
        this.$root.apis.getNewsListByEditor(importParam, this.importSuccess, this.importError)
      }

      if (this.selected === 'videos') {

      }
    },
    importSuccess (_response) {
      let response = _response.body.data
      console.log('=> 調閱新聞清單: ', response)

      // 重新整理資料格式
      let newsList = []
      response.news.forEach(function (_newsItem) {
        newsList.push(formatImportNews(_newsItem))
      })
      console.log('=> 整理後的新聞清單:', newsList)

      // 直接把新聞清單回覆到上層項目
      this.$emit('import', newsList)

      // 執行更新作業
      this.setRegionAutoSetting()
    },
    importError () {
      // this.$root.common.showErrorDialog("新聞文章調閱發生問題")
      this.$root.common.showErrorNotify(_err.body.data)
      return
    },
    reset () {
      this.importMainNewsType = null
      this.importSubNewsType = null
      this.importMethods = null
      this.importCount = null
    },
    getMarketAutoSetting () {
      let vm = this
      console.log('--> 請求獲得版位[' + this.regionType + ']自動帶入設置...')
      this.$root.apis.getMarketAutoSetting(this.regionType, { target_id: this.targetId }, async function (_response) {
        let response = _response.body.data

        // 非自動版面, 就不要載入設定了
        if (response.auto_type !== "1") {
          // 需要重置畫面上的內容
          vm.autoImportNews = '0'
          vm.autoImportTimes = 1
          vm.selected = ''
          vm.importCount = ''
          vm.importMainNewsType = null
          vm.importSubNewsType = null
          vm.NewsImportMethods = null
          vm.currentTags = null
          vm.TagImportMethods = null
          vm.currentVideos = null
          vm.VideoImportMethods = null
          return false
        }

        vm.autoImportNews = await response.auto_type
        vm.autoImportTimes = await response.reload_minute
        vm.selected = await response.reload_function
        vm.importCount = await response.quy

        if (response.reload_function === 'news') {
          vm.importMainNewsType = await (response.main_type !== null) ? response.main_type : false
          vm.importSubNewsType = await (response.sub_type !== null) ? response.sub_type : false
          vm.NewsImportMethods = await (response.sort_by !== null) ? response.sort_by : false
        }
        if (response.reload_function === 'tags') {

          // 處理多個 TAG
          if (response.tags.length > 0) {
            vm.currentTags = response.tags
          }
          // 處理單一 TAG
          else {
            vm.currentTags = await (response.tag !== null) ? response.tag : false
          }
          vm.TagImportMethods = await (response.sort_by !== null) ? response.sort_by : false
        }
        if (response.reload_function === 'videos') {
          vm.currentVideos = await (response.live !== null) ? response.live : false
          vm.VideoImportMethods = await (response.sort_by !== null) ? response.sort_by : false
        }
      })
    },
    setRegionAutoSetting (_importParam) {
      let vm = this
      let sortBy = 0
      if (this.selected === 'news') {
        sortBy = (this.NewsImportMethods !== null) ? this.NewsImportMethods.id : 1
      }
      if (this.selected === 'tags') {
        sortBy = (this.TagImportMethods !== null) ? this.TagImportMethods.id : 1
      }
      if (this.selected === 'videos') {
        sortBy = (this.VideoImportMethods !== null) ? this.VideoImportMethods.id : 1
      }

      console.log('=> 嘗試儲存目標[' + this.targetId + ']版面[' + this.regionType + ']設定...')

      let setting = {
        type: this.regionType,
        target_id: this.targetId,
        auto_type: this.autoImportNews,
        reload_minute: this.autoImportTimes,
        reload_function: this.selected,
        main_type_id: (this.importMainNewsType !== null) ? this.importMainNewsType.id : "",
        sub_type_id: (this.importSubNewsType !== null) ? this.importSubNewsType.id : "",
        tag_id: (this.currentTags !== null) ? this.currentTags : [],
        live_id: (this.currentVideos !== null) ? this.currentVideos.id : "",
        sort_by: sortBy,
        quy: this.importCount
      }

      this.$root.apis.setMarketAutoSetting(this.regionType, setting, function (_response) {
        // 如果是自動設置，需要重新跟後端拿一次資訊
        if (vm.autoImportNews === "1") {
          vm.getMarketAutoSetting()
        }
      })
    },
    addTag (_tag) {
      console.log('=> 新增標籤:', _tag)
    }
  },
  components: {
    Multiselect,
    FastImport
  }
}
</script>

<style lang="sass" scoped>
.action-btn
  margin-left: 6px

@media (max-width: 992px)
  .action-btn
    display: block
    width: 100%
    margin: 0
.min-w960
  min-width: 960px
  max-width: calc(100% - 24px)
.min-w510
  min-width: 510px
</style>